import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { navigate, graphql } from "gatsby"
import { Seo } from "../strapi/Seo"
import styled from "styled-components"
import { theme } from "twin.macro"
import { StrapiMobileTopAction } from "../strapi/StrapiMobileTopAction"
import { StrapiFooter } from "../strapi/StrapiFooter"
import { StrapiTopBar } from "../strapi/StrapiTopBar"
import { StrapiModulesNavigation } from "../strapi/StrapiModulesNavigation"
import { StrapiNavigation } from "../strapi/StrapiNavigation"
import { GlobalStyles } from "../GlobalStyles"
import { usePreferredLanguage } from "../util/usePreferredLanguage"
import { PageContext } from "../contexts/PageContext"
import { SectionRenderer } from "../sections/SectionRenderer"
import { Container } from "../components/Container"

import "../styles.css"

const ModuleNavigationWrapper = styled.div`
  @media (min-width: ${theme("screens.lg")}) {
    position: sticky;
    top: 0;
    margin-top: 142px;
  }
`

export default function ModulesPage({ pageContext, data }) {
  const {
    navigateToPreferredLanguage,
    language,
    defaultLanguage,
    languageLinks,
  } = pageContext

  const [preferredLang] = usePreferredLanguage({
    defaultLanguage,
    languages: Object.keys(languageLinks),
  })

  useEffect(() => {
    if (preferredLang !== language && navigateToPreferredLanguage) {
      navigate(languageLinks[preferredLang])
    }
  }, [language, languageLinks, navigateToPreferredLanguage, preferredLang])

  const sections = data.strapi.page.sections.map((data, index) => (
    <React.Fragment key={`${data.__typename}__${data.id}`}>
      <SectionRenderer data={data} />
    </React.Fragment>
  ))

  return (
    <PageContext.Provider value={{ ...pageContext, data }}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <GlobalStyles />
      <Seo />
      <div className="absolute top-0 flex flex-col w-full min-h-screen">
        <StrapiMobileTopAction />
        <div className="sticky top-0 z-50">
          <StrapiTopBar>
            <StrapiNavigation />
          </StrapiTopBar>
        </div>
        <div className="flex-1 relative w-full z-0">
          <Container className="flex flex-col flex-col-reverse flex-1 relative w-full z-0 mb-xl mt-m lg:flex-row lg:space-x-l">
            <div className="flex items-start lg:w-1/4">
              <ModuleNavigationWrapper className="shadow-xl rounded-3xl w-full p-s my-m">
                <h2 className="text-heading-xs px-m my-s">All modules</h2>
                <StrapiModulesNavigation className="w-full" />
              </ModuleNavigationWrapper>
            </div>
            <div className="flex flex-col flex-1">{sections}</div>
          </Container>
        </div>
        <StrapiFooter />
      </div>
    </PageContext.Provider>
  )
}

export const query = graphql`
  query ModulesPageData($pageId: ID!) {
    strapi {
      ...NavigationActions
      ...NavigationMenus
      ...SinglePlan
      ...Footer
      ...TrialPlan
      ...SubscriptionPlan
      ...FaqItems
      page(id: $pageId) {
        layout
        ...Page
      }
    }
  }
`

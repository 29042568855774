import React from "react"
import { useStrapiNavigationAction } from "./useStrapiNavigationAction"
import { NavigationLink } from "../components/Navigation"
import { useStaticQuery, graphql } from "gatsby"

const NavigationLinkWrapper = ({ data = {}, ...rest }) => {
  const action = useStrapiNavigationAction(data.id)
  const icon = action.icon?.localFile?.publicURL && (
    <img src={action.icon.localFile.publicURL} alt={action.label?.text} />
  )

  return <NavigationLink icon={icon} {...rest} {...action.props} />
}

export const StrapiModulesNavigation = props => {
  const data = useStaticQuery(
    graphql`
      query StrapiModulesNavigationQuery {
        strapi {
          modulesNavigation {
            links {
              action {
                id
              }
            }
          }
        }
      }
    `
  )

  const links =
    data.strapi?.modulesNavigation?.links?.map(data => (
      <div>
        <NavigationLinkWrapper
          activeClassName="bg-gray-100"
          className="hover:bg-gray-100"
          key={data.action?.id}
          data={data.action}
        />
      </div>
    )) || []

  return links
}
